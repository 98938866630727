<template>
 
  <div class="cats">

    <div class="cat" v-for="(category, index) in categories" :key="index">
      
      <UiChartDonut 
        :data="category" 
        :size="10" 
        :size-px="'70px'"
        :strokeWidth="2.3">
        <div class="selected_chart_title">{{ parseInt(category[0].value) }}%</div> 
      </UiChartDonut>

      <div class="list">
        <div class="rh">{{ categoryName(index) }} preferred:</div>
        <div class="rhl">
          <div v-for="(item, index) in category" :key="index">
            <div class="dt" :style="'background-color:' + item.color"></div>
            {{ item.label }}
            <!-- {{ item.value }} -->
          </div>
        </div>
      </div>
    </div>

     
  </div> 
        
</template>

<script setup>



const { t } = useI18n();
const emits = defineEmits(['follow', 'unfollow'])



const props = defineProps({
  
  preferred: {
    type: Array,
    default: null,
  },
})

const getColorByCategoryNumber = (category, index) => {

if (category === "seed") {
  let colorsRandom = [
    '#1A5319',
    '#508D4E',
    '#80AF81', 
    '#D6EFD8',
  ];
  return colorsRandom[index];
}else if (category === "nutrient") {
  let colorsRandom = [
    '#461959',
    '#7A316F',
    '#CD6688', 
    '#AED8CC',
  ];
  return colorsRandom[index];
}else if (category === "tent") {
  let colorsRandom = [
    '#83764F',
    '#A2A378',
    '#A0D8B3', 
    '#E5F9DB',
  ];
  return colorsRandom[index];
}else{
  let colorsRandom = [
    '#5356FF',
    '#378CE7',
    '#67C6E3', 
    '#DFF5FF',
  ];
  return colorsRandom[index];
}
  


}
  

const avtiveNutrinetId = ref(1);


const categoryName = (category) => {
  if(category == 'seed') return 'Breeder';
  return t(`category_${category}`);
}

const categories = computed(() => {
  var categories = {};
 

  props.preferred.forEach((item, index) => {

    if(categories[item.category] == undefined){
      categories[item.category] = [];
    }
    categories[item.category].push({
      id: item.id,
      // color: getColorByNumber(categories[item.category].length),
      color: getColorByCategoryNumber(item.category, categories[item.category].length),
      value: item.usage,
      label: item.name    
    });
  }); 
  return categories;
})



</script>




<style scoped>
 

 .cats{
  display: flex;
  width: 100%;
  overflow-x: auto;
  gap: 1rem;
  align-items: flex-start;
 } 

 .cats::-webkit-scrollbar {
    display: none;
  }
  
  .cats {
    -ms-overflow-style: none; 
    scrollbar-width: none; 
  }


 .cats .cat{
  display: flex;
  width: auto;
  gap: 10px;
  flex-shrink: 0;
  align-items: flex-start;
 }
 .cats .cat .list{
  display: flex;
  gap: 2px;
  flex-direction: column;

 }
 .cats .cat .list .rh{
  text-transform: capitalize;
  font-size: 0.8rem;

  font-weight: bold;
 }
 .cats .cat .list .rhl{
  
  text-transform: capitalize;
 }
 .cats .cat .list .dt{
  display: inline-block;
  border-radius: 50%;
  width: 6px;
  aspect-ratio: 1/1;
  height: 6px;
  vertical-align: middle;
  margin-right: 3px;
 }

 .selected_chart_title{
  font-size: 0.6rem;
  line-height: 0.6rem;
  margin-top: 0px;
  font-weight: bold;
 }
 /* .cats .cat:deep(.chart){
  margin-top: 10px;
 } */
</style>
